import styled from 'styled-components';

import Box from '@simplate/ui/Box';
import Text from '@simplate/ui/Text';

const Label = styled(Box)`
	display: inline-flex;
	background-color: ${({ color, theme }) => theme.utils.colors.fade(theme.colors[color], 0.16)};
	border-radius: ${({ theme }) => theme.radii[1]};
	padding-left: ${({ theme }) => theme.space[2]};
	padding-right: ${({ theme }) => theme.space[2]};
	& > ${Text} {
		color: ${({ color, theme }) => theme.colors[color]};
		${({ theme }) => theme.textStyles.web.label.xs};
		line-height: ${({ theme }) => theme.fontSizes[7]};
		text-transform: uppercase;
	}
`;

export default Label;