import React from 'react';
import useRequestModal from 'context/Layout/hooks/useRequestModal';

import Button from './Button';

const RequestBetaButton = props => {
	const [, setRequestModal] = useRequestModal();
	return (
		<Button {...props} onClick={() => setRequestModal(true)} />
	);
}

export default RequestBetaButton;
