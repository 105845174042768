import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import Box from '../Box';

const sizes = variant({
	prop: 'size',
	variants: {
		xs: {
			width: 3,
			height: 3,
		},
		sm: {
			width: 4,
			height: 4,
		},
		md: {
			width: 7,
			height: 7
		},
		lg: {
			width: 8,
			height: 8
		},
	}
});

const iconSizes = variant({
	prop: 'size',
	variants: {
		xs: {
			width: 1,
			height: 1,
		},
		sm: {
			width: '0.875rem',
			height: '0.875rem',
		},
		md: {
			width: 4,
			height: 4
		},
		lg: {
			width: 5,
			height: 5
		},
	}
});

const color = ({ color = 'primary', theme }) => {
	return css`
		background-color: ${({ theme }) => theme.colors[color]};
		background-image: ${({ theme }) => theme.gradients[color]};
		box-shadow: 0px 2px 4px ${({ theme }) => theme.utils.colors.fade(theme.shadowColors[color] || theme.colors.shadow, 0.32)};
	`;
}

const IconBubble = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	${sizes};
	${color};

	& > svg {
		${iconSizes};
		color: ${({ theme }) => theme.colors.white};
		fill: ${({ theme }) => theme.colors.white};
	}
`;

IconBubble.defaultProps = {
	borderRadius: 'circle'
}

export default IconBubble;
