import React, { useEffect, useState, useRef, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from '@simplate/styles';
import Box from '@simplate/ui/Box';

import { LayoutContext } from 'context/Layout';

function useIntersectionObserver(elementRef, {
    threshold = 0,
    root = null,
    rootMargin = '0% 0% -95% 0%',
    freezeOnceVisible = false,
}) {
  const [entry, setEntry] = useState()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]) => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry
}


const ThemedSection = ({ children, theme = 'light', from = 'light' }) => {
	const ref = useRef();

	const {  setHeaderTheme } = useContext(LayoutContext);
	const entry = useIntersectionObserver(ref, {});
	const isVisible = !!entry?.isIntersecting;


	useEffect(() => {
		if (isVisible) {
			setHeaderTheme(theme);
		} else {
			setHeaderTheme(from)
		}
	}, [isVisible, theme, from, setHeaderTheme]);

	return (
		<ThemeProvider theme={themes[theme]}>
			<Box ref={ref} backgroundColor="background">
				{children}
			</Box>
		</ThemeProvider>
	);
}

export default ThemedSection;
