import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from '@simplate/ui/Box';
import TextGroup from '@simplate/ui/TextGroup';

import { grid } from 'styles/grid';

const alignVariant = ({ offset, span }) => variant({
	prop: 'align',
	variants: {
		left: {
			alignItems: 'flex-start',
			[`& > ${Box}`]: {
				gridColumn: [`span 4`, 'span 4', `span ${span + 2}`, `span ${span}`]
			}
		},
		center: {
			alignItems: 'center',
			marginX: 'auto',
			[`& > ${Box}`]: {
				gridColumn: [`span 4`, 'span 4', `${offset - 1} / span ${span + 2}`, `${offset} / span ${span}`]
			}
		},
		right: {
			alignItems: 'flex-end',
			[`& > ${Box}`]: {
				gridColumn: [`span 4`, 'span 4', `span ${span + 2}`, `span ${span}`]
			}
		},
	}
});

const TitleGroupInner = ({ align, className, children, maxWidth }) => (
	<Box className={className}>
		<Box align={align} maxWidth={maxWidth}>
			<TextGroup align={align} space={[4, 6, 6, 8]}>
				{children}
			</TextGroup>
		</Box>
	</Box>
);

const TitleGroup = styled(TitleGroupInner)`
	${grid};
	${alignVariant};
`;

TitleGroup.defaultProps = {
	align: 'left',
	offset: 3,
	span: 8,
}

export default TitleGroup;