import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { grid } from 'styles/grid';

import Box from '@simplate/ui/Box';

const Root = styled(Box)`
	& > * + * {
		margin-top: ${({ theme }) => theme.space[9]}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		& > * + * {
			margin-top: ${({ theme }) => theme.space[13]}
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
		& > * + * {
			margin-top: ${({ theme }) => theme.space[14]}
		}
	}
`;

const ImageCard = styled(Box)`
	background-color: ${({ theme }) => theme.colors.surface};
	border-radius: ${({ theme }) => theme.radii[1]};
	overflow: hidden;
	box-shadow: 0px -8px 120px ${({ theme }) => theme.utils.colors.fade(theme.colors.trueBlack, 0.32)};
	transform: translateZ(0);

	& video, & iframe {
		width: 100%;
		object-fit: contain;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.mobileLg}) {
		border-radius: ${({ theme }) => theme.radii[2]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.tabletSm}) {
		border-radius: ${({ theme }) => theme.radii[5]};
	}
`;

const Grid = styled(Box).attrs(props => ({
	columns: props.children?.length || 1
}))`
	${grid};
	${
		variant({
			prop: 'columns',
			variants: {
				1: {
					"& > *": {
						gridColumn: ['span 4', 'span 4', '4 / span 6'],
					},							
				},
				2: {
					"& > *:first-child": {
						gridColumn: ['span 4', 'span 4', '2 / span 5'],
					},				
					"& > *:last-child": {
						gridColumn: ['span 4', 'span 4', '7 / span 5']
					}				
				},
				3: {
					"& > *": {
						gridColumn: 'span 4',
					},	
				},
			}
		})
	}
`;

const FeatureImage = ({ children, card = true, ImageComponent, alt, src }) => {
	const image = src ? getImage(src) : null
	return (
		<Root>
			<Box as={card ? ImageCard : null}>
				{
					ImageComponent ? ImageComponent : (
						<GatsbyImage
							image={image}
							alt={alt}
						/>
					)
				}
			</Box>
			<Grid>
				{children}
			</Grid>
		</Root>
	);
};

export default FeatureImage;