import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themes } from '@simplate/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Box from '@simplate/ui/Box';

import Container from './Container';
import Section from './Section';

const Root = styled(Box)`
	overflow: hidden;
	padding-bottom: 30%;
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		height: 100vh;
		max-height: 900px;
		padding-bottom: 0;
	}
`;

const ImageCard = styled(Box)`
	position: absolute;
	top: 100%;
	left: 20px; 
	right: 20px;
	background-color: ${({ theme }) => theme.colors.black};
	border-radius: ${({ theme }) => theme.radii[1]};
	overflow: hidden;
	box-shadow: 0px -8px 120px ${({ theme }) => theme.utils.colors.fade(theme.colors.trueBlack, 0.32)};

	@media (min-width: ${({ theme }) => theme.breakpoints.mobileLg}) {
		border-radius: ${({ theme }) => theme.radii[2]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.tabletSm}) {
		border-radius: ${({ theme }) => theme.radii[5]};
	}
`;

const BetaCTA = ({ children, src }) => {
	const image = src ? getImage(src) : null
	return (
		<ThemeProvider theme={themes.dark}>
			<Root gradient="green">
				<Container>
					<Section>
						{children}
					</Section>
					<ImageCard>
						<GatsbyImage image={image} alt="Request Beta Access" />
					</ImageCard>
				</Container>
			</Root>
		</ThemeProvider>
	);
}

export default BetaCTA;