import * as React from "react"
import { graphql } from 'gatsby';

import Box from '@simplate/ui/Box';
import IconBubble from '@simplate/ui/IconBubble';
import { ArchiveIcon, BrandKitIcon, ChangeOrderIcon, HandoffIcon, RevenueIcon, TeamIcon } from '@simplate/ui/Icon/icons';

import Button from 'components/RequestBetaButton'
import Container from 'components/Container'
import FeatureCard from 'components/FeatureCard'
import FeatureImage from 'components/FeatureImage'
import Section from 'components/Section'
import Label from 'components/Label'
import Link from 'components/Link'
import Layout from 'components/layout'
import Seo from 'components/seo'
import Text from 'components/Text'
import ThemedSection from 'components/ThemedSection';
import TextGroup from 'components/TextGroup';
import TitleGroup from 'components/TitleGroup'
import BetaCTA from "../components/BetaCTA";

const IndexPage = ({ data }) => (
  <Layout theme="light">
    <Seo 
		title="Simplate" 
		metaDescription="Simplate is a template-driven video platform built for efficiency." 
	/>
    <Container marginTop={[10, 10, 12]}>
		<Section>
			<TitleGroup align="center">
				<Text as="h2" textStyle="title.lg">
					Pure And Simple.<br />
					Video Versioning At Scale.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Turn your videos into a simple template so anyone on your team can create hundreds of variations.
				</Text>
				<Button>
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage src={data.creatorImage} alt="Simplate Creator">
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Video versioning at scale
					</Text>
					<Text color="altText" textStyle="text.md">
						Create hundreds of variations by turning professionally built video into a simple template. We call that a Simplate.
					</Text>
				</TextGroup>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Real-time Collaboration
					</Text>
					<Text color="altText" textStyle="text.md">
						A centralized platform for collaboration. Your team can adjust and give feedback in real-time from the same project.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
		<Section>
			<TitleGroup align="center">
				<Text as="h2" gradient="purple" textStyle="title.md">
					A centralized video versioning platform for teams.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Globally share files and collaborate on templates across any sized organization directly in Simplate.
				</Text>
			</TitleGroup>
			<Box display="grid" gridRowGap={9} gridColumnGap={5} gridTemplateColumns={['1fr', '1fr', '1fr 1fr', 'repeat(3, 1fr)']}>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="yellow" size={['md', 'md', 'md', 'lg']}>
						<HandoffIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						Consider it a handoff
					</Text>
					<Text color="altText" textStyle="text.md">
						Your video professional selects the video's editable fields. Your team makes the changes from there.
					</Text>
				</TextGroup>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="green" size={['md', 'md', 'md', 'lg']}>
						<BrandKitIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						Brand kits
					</Text>
					<Text color="altText" textStyle="text.md">
						Save time with auto-population. 
						Fonts, logos and colors are inserted automatically from your Brand Assets.
					</Text>
				</TextGroup>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="blue" size={['md', 'md', 'md', 'lg']}>
						<TeamIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						Flexible team structure
					</Text>
					<Text color="altText" textStyle="text.md">
						Administrators can organize by team, project or campaign. It's up to you.
					</Text>
				</TextGroup>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="purple" size={['md', 'md', 'md', 'lg']}>
						<RevenueIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						New revenue channels
					</Text>
					<Text color="altText" textStyle="text.md">
						You made it, now sell it! Share Simplates with your clients in your own private marketplace.
					</Text>
				</TextGroup>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="orange" size={['md', 'md', 'md', 'lg']}>
						<ChangeOrderIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						Small change orders
					</Text>
					<Text color="altText" textStyle="text.md">
						Never make small last minute changes again. Let your clients sweat the small stuff.
					</Text>
				</TextGroup>
				<TextGroup>
					<IconBubble borderRadius="squircle" color="red" size={['md', 'md', 'md', 'lg']}>
						<ArchiveIcon />
					</IconBubble>
					<Text as="h4" textStyle="title.xs">
						Accessible archiving
					</Text>
					<Text color="altText" textStyle="text.md">
						Offer your clients new package deals for things like lifetime access to editing and exporting. 
					</Text>
				</TextGroup>
			</Box>
			<Box display="flex" alignItems="center" justifyContent="center">
				<Link to="/creators/">Learn more about Simplate for Creators</Link>
			</Box>
		</Section>
	</Container>
	<ThemedSection theme="dark" from="light">
		<Container>
			<Section>
				<TitleGroup align="left">
					<Text as="h2" gradient="green" textStyle="title.md">
						Built for Teams.<br />
						Built for Speed.
					</Text>
					<Text textStyle="text.lg" color="altText">
						Increase your in-house marketing capacity at scale and explore new strategic possibilities.
					</Text>
				</TitleGroup>
				<Box paddingTop={12} display="grid" gridAutoRows={['400px', '440px', "576px", "560px", '640px']} gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]} gridGap={9}>
					<FeatureCard 
						fullWidthImg={false}
						gradient="blue"
						title="Repurpose Projects"
						text="Don't start from scratch. Create a new template from the original project to significantly extend its shelf life."
						src={data.repurposeProjects}
					/>
					<FeatureCard 
						gradient="green"
						title="Globally shared assets"
						text="Universally share elements with collaborators within Simplate's cloud-based asset library."
						src={data.sharedAssets}
						objectPosition="100% 50%"
					/>
					<FeatureCard 
						gradient="purple"
						title="A/B Testing"
						text="Simplate makes it cost-effective to produce multiple variations of any campaign video."
						src={data.abTesting}
					/>
					<FeatureCard 
						fullWidthImg={false}
						gradient="yellow"
						title="Targeted Messaging"
						text="Create new and unique message variations within your hero marketing videos, all in-house."
						src={data.targetedMessaging}
					/>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Link color="text" to="/teams/">Learn more about Simplate for Teams</Link>
				</Box>
			</Section>
		</Container>
	</ThemedSection>
	<Container>
		<Section>
			<TitleGroup align="left">
				<Text as="h2" gradient="blue" textStyle="title.md">
					Unrivaled versioning power. Set it and forget it.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Automatic video generation, live data connections and more with these upcoming features.
				</Text>
				<Button color="blue" size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage alt="Coming Soon" src={data.comingSoon}>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Integrate social tools
					</Text>
					<Text color="altText" textStyle="text.md">
						Combine Simplate's automation capabilities with your favored social media management tool for complete automation from population to posting.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Live data feeds
					</Text>
					<Text color="altText" textStyle="text.md">
						Set up a connection to any data source to drive the content of your template, no matter your expertise. Set event triggers and post near real-time topical videos.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Public Marketplace
					</Text>
					<Text color="altText" textStyle="text.md">
						A new passive income channel for every artist looking for independence. We will provide everything you need to produce quality video templates that sell. 
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
	</Container>
	<BetaCTA src={data.creatorImage}>
		<TitleGroup align="center">
			<Label color="text"><Text>Start Creating</Text></Label>
			<Text as="h2" textStyle="title.lg">
				Chat with us about private beta today.
			</Text>
			<Button size="lg">
				<Text gradient="green">Request Beta Access</Text>
			</Button>
		</TitleGroup>
	</BetaCTA>
  </Layout>
);

export const pageQuery = graphql`
	query LandingPage {
		creatorImage: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"app"}, name:{eq:"creator_home"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
		repurposeProjects: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"landing_page"}, name:{eq:"repurpose_projects"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
		sharedAssets: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"landing_page"}, name:{eq:"shared_assets"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
		abTesting: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"landing_page"}, name:{eq:"a_b_testing"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
		targetedMessaging: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"landing_page"}, name:{eq:"targeted_messaging"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
		comingSoon: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"coming_soon"}, name:{eq:"coming_soon_home"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 50, formats: WEBP, placeholder: BLURRED)
			}
		}
	}
`;
export default IndexPage
