import { css } from 'styled-components';

// TODO: This row spacing of 9, 13, 14 should be a system
// TODO: It's used: FeatureImage.jsx & Section.jsx for spacing between children
// TODO: And here as a row-gap value.

export const grid = css`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${({ theme }) => theme.space[6]};
	grid-row-gap: ${({ theme }) => theme.space[9]};
	
	@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: ${({ theme }) => theme.space[8]};
		grid-row-gap: ${({ theme }) => theme.space[13]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
		grid-column-gap: ${({ theme }) => theme.space[9]};
		grid-row-gap: ${({ theme }) => theme.space[14]};
	}
`;