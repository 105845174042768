import React from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import Box from '@simplate/ui/Box';
import TextGroup from '@simplate/ui/TextGroup';

import Text from 'components/Text';

const Root = styled(Box)`
	display: grid;
	grid-template-rows: 1fr min-content;
	grid-gap: ${({ theme }) => theme.space[4]};
	border-radius: ${({ theme }) => theme.radii[5]};
	padding-top: ${({ theme }) => theme.space[8]};

	@media(min-width: ${({ theme }) => theme.breakpoints.tabletSm}) {
		padding-top: ${({ theme }) => theme.space[10]};
	}

	@media(min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-top: ${({ theme }) => theme.space[12]};
	}
`;

const ImgWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	min-height: 100%;
	${'' /* & img {
		position: absolute;
		top: ${({ $fullWidthImg }) => $fullWidthImg ? "0" : "50%"};
		transform: ${({ $fullWidthImg }) => $fullWidthImg ? "translate3d(0, 0, 0)" : "translate3d(0, -50%, 0)"};
		width: ${({ $fullWidthImg }) => $fullWidthImg ? '100%' : 'calc(100% - 64px)' };
	} */}
`;

const FeatureCard = ({ gradient, objectFit = 'contain', objectPosition = "50% 50%", fullWidthImg = true, src, title, text }) => {
	const image = getImage(src);
	return (
		<Root gradient={gradient}>
			<ImgWrapper $fullWidthImg={fullWidthImg}>
				<GatsbyImage objectPosition={objectPosition} objectFit={objectFit} alt={title} image={image} />
			</ImgWrapper>
			<TextGroup paddingX={[8, 9, 9, 10, 12]} paddingBottom={[8, 9, 9, 10, 12]} space={5}>
				<Text as="h4" textStyle="title.xs">
					{title}
				</Text>
				<Text color="text" textStyle="text.md">
					{text}
				</Text>
			</TextGroup>
		</Root>
	)
}

export default FeatureCard;
