import styled from 'styled-components';

import Box from '@simplate/ui/Box';

const Section = styled(Box)`	
	padding-top: ${({ theme }) => theme.space[10]};
	padding-bottom: ${({ theme }) => theme.space[9]};

	& > * + * {
		margin-top: ${({ theme }) => theme.space[9]};
	}

	& + & {
		padding-top: ${({ theme }) => theme.space[9]};
	}

	&:last-of-type {
		padding-bottom: ${({ theme }) => theme.space[10]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
		padding-top: ${({ theme }) => theme.space[14]};
		padding-bottom: ${({ theme }) => theme.space[13]};
		
		& > * + * {
			margin-top: ${({ theme }) => theme.space[13]};
		}

		& + & {
			padding-top: ${({ theme }) => theme.space[13]};
		}
		
		&:last-of-type {
			padding-bottom: ${({ theme }) => theme.space[14]};
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
		padding-top: ${({ theme }) => theme.space[15]};
		padding-bottom: ${({ theme }) => theme.space[14]};
		
		& > * + * {
			margin-top: ${({ theme }) => theme.space[14]};
		}
		
		& + & {
			padding-top: ${({ theme }) => theme.space[14]};
		}

		&:last-of-type {
			padding-bottom: ${({ theme }) => theme.space[15]};
		}
	}
`;

export default Section;