import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@simplate/ui/Box';
import { LinkIcon } from '@simplate/ui/Icon/icons';

import Text from './Text';

const Root = styled(Box)`
	display: flex;
	align-items: flex-end;

	& svg {
		margin-left: ${({ theme }) => theme.space[2]};
	}
`;

const Link = ({ children, color = 'secondary', textStyle = "label.lg", to }) => {
	return (
		<GatsbyLink to={to}>
			<Root color={color}>
				<Text color={color} textStyle={textStyle}>
					{children}
				</Text>
				<LinkIcon color={color} size={3} />
			</Root>
		</GatsbyLink>
	);
};

export default Link;
